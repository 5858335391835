<template>
    <div class="pageContainer targetPage flexCloumn">
        <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
            <div class="flexStart flex_1">
                <tchtree v-model="searchForm.class_ids"></tchtree>
                <el-input placeholder="请输入关键字" v-model="searchForm.keyword" size="small" class="input-with-select"
                    style="width:300px" clearable>
                    <el-select v-model="searchForm.ktype" slot="prepend" placeholder="请选择" style="width:120px">
                        <el-option label="学生姓名" value="stu_name"></el-option>

                    </el-select>
                </el-input>
                <el-date-picker size="small" style="width:150px;margin-left:10px" v-model="searchForm.month"
                    value-format="yyyy-MM" type="month" placeholder="选择月">
                </el-date-picker>

                <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary" @click="() => { page.current_page = 1; getList()}">
                    搜索
                </el-button>
            </div>

            <div class="flexStart"
                style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">


                <el-button v-if="$store.state.config.sync_data" icon="el-icon-refresh" style="margin-left:10px" size="small"
                    type="primary" @click="tongbuJava">
                    迁移老版本
                </el-button>
            </div>
        </el-row>
        <div class="dividerBar"></div>

        <el-table v-tableFit :data="DataList" height="100%" size="small" stripe border
            style="width:fit-content;width:100%;">
            <el-table-column type="index" width="65" label="序号" align="center" />

            <el-table-column prop="stu_name" label="学生姓名" width="135" align="center" />
            <el-table-column prop="class_name" label="班级" show-overflow-tooltip min-width="130" align="center" />
            <el-table-column prop="back_date" label="返校时间" width="140" align="center" />
            <el-table-column prop="create_name" label="创建人" width="135" align="center" > </el-table-column>
            <el-table-column prop="creat_date" label="创建时间" show-overflow-tooltip min-width="200" align="center" > </el-table-column>

            <el-table-column fixed="right" label="操作" width="120" align="center">
                <template slot-scope="scope">
                    <el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info"
                        iconColor="red" @confirm="handleDel(scope.row)" placement="left" title="是否确定删除？">
                        <el-tag class="pointer" slot="reference" size="mini" type="danger">删除</el-tag>
                    </el-popconfirm>

                </template>
            </el-table-column>
        </el-table>

        <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
            :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
            :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
        </el-pagination>
    </div>
</template>

<script>
import tchtree from "../com/tchTree.vue";

export default {
    components: {
        tchtree
    },
    data() {
        return {
            temphead: "//school.mmteck.cn/static/temp.png",

            dialogshow: false,
            searchForm: {
                class_ids: "",
                sp_status: "",
                ktype: "stu_name",
                keyword: "",
                month: ""

            },
            EditItem: {
                id: 0,
                group_name: "",
                needpic: "",
                outside: "",
                latesign: "",
            },
            DataList: [],

            page: {
                count: 0,
                current_page: 1,
                per_page: 50,
                total_page: 0
            }


        };
    },
    mounted() {
        let year = new Date().getFullYear()
        let month = new Date().getMonth() + 1
        this.searchForm.month = year + '-' + month
        this.getList()
    },
    methods: {
        getList() {
            this.$http.post("/api/stu_return_to_school_list", {
                page: this.page.current_page,
                pagesize: this.page.per_page,
                ktype: this.searchForm.ktype,
                keyword: this.searchForm.keyword,
                month: this.searchForm.month,
                class_ids: this.searchForm.class_ids

            }).then(res => {
                this.DataList = res.data.data
                this.page = res.data.page
            })
        },


        handleDel(row) {
            this.$http.post("/api/stu_return_to_school_delete", {
                id: row.id
            }).then(res => {
                this.$message.success('删除成功')
                this.getList();
            })
        },
        handlePageChange(page) {
            this.page.current_page = page
            this.getList()
        },
        handleSizeChange(val) {
            this.page.current_page = 1;
            this.page.per_page = val;
            this.getList()
        },
        tongbuJava() {
            this.$message.error('敬请期待')
            return

            this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let loadingInstance = this.$loading({
                    lock: true,
                    text: "已发送请求，请稍等...",
                    spinner: 'el-icon-loading'
                })
                this.$http.post("/api/sync_stu_leave", {}).then(res => {
                    loadingInstance.close()
                    this.$message.success('后台同步中，请刷新查看')
                    this.getList();
                }).catch(err => {
                    this.$message.error('同步失败')
                    loadingInstance.close()
                })

            }).catch(() => {

            });
        }
    },
}
</script>

<style scoped="scoped">
.spuser {
    display: inline-block;
    border: 1px solid rgb(255, 148, 62);


    padding: 2px;
    margin-right: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    padding: 4px 10px;
}

.spuser img {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
}
</style>
